export default {
  data() {
    return {
      time: null,
    };
  },
  methods: {
    debounce(callback, ms = 400) {
      this.killDebounce();

      this.time = setTimeout(() => callback(), ms);
    },
    killDebounce() {
      if (this.time) {
        clearTimeout(this.time);
      }
    },
  },
  beforeDestroy() {
    this.killDebounce();
  },
};

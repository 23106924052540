<template>
  <div class="page">
    <!-- Statistique-->
    <div class="colors" style="margin-bottom: 30px;">
      <div
        class="
          grid sm:grid-cols-2 lg:grid-cols-4 gap-1
          vs-con-loading__container
        "
        id="stat-card"
      >
        <VxCard
          class="cursor-pointer"
          v-for="({label, value}, key) in statusFilters"
          v-on:click.native="toggleStatus(key)"
          :card-background="`var(--color-${key.toLowerCase()})`"
          :content-color="colors.white"
          :key="label"
          :subtitle="label"
          :title="value.toString()"
          :title-color="colors.white"
        />
      </div>
    </div>

    <!-- Tableau -->
    <VxCard>
      <div class="flex flex-wrap justify-between items-center">
        <!-- Filtres -->
        <div class="flex gap-3 flex-wrap">
          <!-- Code Postal -->
          <VsInput
            class="md:flex-1 md:min-w-300 w-full"
            label="Code postal"
            placeholder="Code postal"
            :value="filters.postalCode"
            @input="(value) => handleFilterText('postalCode', value)"
          />
          <!-- Formule -->
          <vs-select
            class="md:flex-1 w-full md:min-w-300"
            :value="filters.formulas.value"
            @input="handleChangeFormula"
            label="Formule"
            placeholder="Code postal"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="item,index in formulasOptions"
            />
          </vs-select>
          <!-- Nom du négo -->
          <VsInput
            class="md:flex-1 w-full md:min-w-300"
            label="Négociateur"
            placeholder="Nom du négociateur"
            :value="filters.agent"
            @input="(value) => handleFilterText('agent', value)"
          />
          <!-- Prestataire -->
          <VsInput
            v-if="this.$store.state.AppActiveUser.isSuperAdmin"
            class="md:flex-1 w-full md:min-w-300"
            label="Préstataire"
            placeholder="Préstataire"
            :value="filters.serviceProvider"
            @input="(value) => handleFilterText('serviceProvider', value)"
          />
          <!-- Nom téléconseiller -->
          <VsInput
            class="md:flex-1 w-full md:min-w-300"
            label="Téléconseiller"
            placeholder="Nom du téléconseiller"
            :value="filters.teleconsultant"
            @input="(value) => handleFilterText('teleconsultant', value)"
          />
          <!-- Référence -->
          <VsInput
            class="md:flex-1 w-full md:min-w-300"
            label="Référence"
            placeholder="Référence"
            :value="filters.reference"
            @input="(value) => handleFilterText('reference', value)"
          />
        </div>
      </div>

      <div class="mt-5">
        <div class="tab-text">
          <!-- ACTION - DROPDOWN -->
          <div class="flex mb-4 flex-wrap gap-3">
            <div class="flex-1">
              <!-- <VsDropdown vs-trigger-click class="dd-actions cursor-pointer">
                <div class="
                  p-4 w-32 w-full
                  shadow-drop rounded-lg d-theme-dark-bg cursor-pointer
                  flex items-center justify-center
                  text-lg font-medium
                ">
                  <span class="mr-2">Actions</span>
                  <FeatherIcon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <VsDropdownMenu>
                  <VsDropdownItem>
                    <span class="flex items-center" @click="popupStatut=true">
                      <FeatherIcon icon="CheckIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>Changer le statut</span>
                    </span>
                  </VsDropdownItem>

                  <VsDropdownItem>
                    <span class="flex items-center" @click="popupActive=true">
                      <FeatherIcon icon="UserCheckIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>Affecter</span>
                    </span>
                  </VsDropdownItem>

                  <VsDropdownItem>
                    <span class="flex items-center" @click="valider">
                      <FeatherIcon icon="CheckSquareIcon" svgClasses="h-4 w-4" class="mr-2"  />
                      <span>Valider</span>
                    </span>
                  </VsDropdownItem>
                </VsDropdownMenu>
              </VsDropdown> -->
            </div>
            <vs-button
              class="vs-con-loading__container"
              id="get-csv-button"
              @click="getCsv()"
            >Exporter .xslx</vs-button>
          </div>

          <!-- Tableau de données -->
          <vs-table
            id="table"
            stripe
            :data="orders"
            :sst="true"
            @selected="handleSelected"
          >
            <!-- Head -->
            <template slot="thead">
              <template v-for="value in columns">
                <vs-th
                  v-if="(value.superAdmin && isSuperAdmin) || (!value.superAdmin)"
                  :key="value.label"
                >
                  {{ value.label }}
                </vs-th>
              </template>
            </template>

            <!-- Data -->
            <template slot-scope="{data}">
              <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
                <template v-for="value in columns">
                  <vs-td
                    v-if="(value.superAdmin && isSuperAdmin) || (!value.superAdmin)"
                    :class="{
                      'table-cell': true,
                      'table-cell-status': value.field === 'statut'
                    }"
                    :data-status="value.field === 'statut' && item[value.field].toLowerCase()"
                    :key="value.label"
                  >
                    {{ formatTableData(item, value) }}
                  </vs-td>
                </template>
              </vs-tr>
            </template>
          </vs-table>

          <VsPagination
            class="mt-3"
            :total="pagination.total"
            :max="9"
            :value="pagination.currentPage"
            @input="handleChangePage"
          />
        </div>
      </div>

      <VsPopup :active.sync="popupActive" title="Affectation au prestataire">
        <b>Préstataire</b> :

        <select class="vs-select w-full" v-model="prestataireNew">
          <option v-for="item in optionsPrestataire" :key="item.id" :value="item">
            {{item.name }}
          </option>
        </select>

        <br>

        <div v-if="prestataireNew.name == 'PP'" class="mt-5">
          <b>Téleconseiller</b> :

          <select class="vs-select w-full " v-model="teleconseillerNew" >
            <option v-for="item in optionsTeleconseiller" :key="item.value" :value="item.value">
              {{item.label}}
            </option>
          </select>

          <br>
        </div>

        <VsButton @click="afftecter()" class="mr-3 mb-2 mt-10 float-right">Valider</VsButton>
      </VsPopup>

      <VsPopup :active.sync="popupStatut" title="Changer le statut">
        <b>Statut</b> :

        <select class="vs-select w-full" v-model="newStatus">
          <option v-for="item in statusList" :key="item.value" :value="item.value">
            {{item.name }}
          </option>
        </select>

        <br>

        <span>
          * <small>
            Pour les commandes en attente,
            veuillez passer par le bouton "valider"ou validez chaque commande séparément.
          </small>
        </span>

        <VsButton @click="changeStatus()" class="mr-3 mb-2 mt-10 float-right">Valider</VsButton>
      </VsPopup>
    </VxCard>
  </div>
</template>

<script>
// https://www.ag-grid.com/vue-data-grid/
import get from 'lodash/get';
import { colors } from '@/../themeConfig';

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { format, parseISO } from 'date-fns';
import debounce from '@/mixins/debounce';
import columns from './columns';

export default {
  name: 'AdminOrders',
  mixins: [debounce],
  data() {
    return {
      filters: {
        agent: '',
        formulas: {
          value: null,
        },
        postalCode: '',
        reference: '',
        serviceProvider: '',
        status: null,
        teleconsultant: '',
      },
      isAdmin: this.$store.state.AppActiveUser.isAdmin,
      isSuperAdmin: this.$store.state.AppActiveUser.isSuperAdmin,
      columns,
      loading: false,
      statusFilters: {
        ALL: { label: 'Total', value: 0 },
        EA: { label: 'En attente', value: 0 },
        EP: { label: 'En attente de paiement', value: 0 },
        EC: { label: 'En cours', value: 0 },
        CLO: { label: 'Cloturés', value: 0 },
        CB: { label: 'Crédit boutique', value: 0 },
        SB: { label: 'Suspendus', value: 0 },
        NR: { label: 'Non renseigné', value: 0 },
      },
      pagination: {
        enabled: true,
        perPage: 10,
        mode: 'records',
        totalItems: 0,
        total: 1,
        currentPage: 1,
      },
      prestataireNew: '',
      optionsTeleconseiller: '',
      optionsPrestataire: '',
      popupActive: false,
      newStatus: '',
      statusList: [
        { value: 'EC', name: 'En cours' },
        { value: 'CLO', name: 'Clôturées' },
        { value: 'SB', name: 'Suspendues' },
      ],
      popupStatut: false,
      colors,
      orders: [],
      gridApi: null,
    };
  },
  computed: {
    formulasOptions() {
      return [
        { value: null, text: 'Toutes' },
        ...Object.values(this.$store.state.formulas).map((formula) => ({
          text: formula.libelle,
          value: formula.libelle,
        })),
      ];
    },
  },
  methods: {
    formatTableData(item, value) {
      const data = get(item, value.field);

      if (Array.isArray(data)) {
        return data.toString();
      }

      const date = parseISO(data);
      if (Number(date) && new Date(date) instanceof Date) {
        return format(new Date(date), 'MM/dd/yyyy');
      }

      return data;
    },
    handleSelected({ id }) {
      this.$router.push({ name: 'admin-orders-id', params: { id } });
    },
    handleFilterText(key, value) {
      if (this.filters[key] !== value) {
        this.pagination.currentPage = 1;
        this.filters[key] = value;
        this.debounce(() => {
          this.getOrders();
        });
      }
    },
    handleChangeFormula(formula) {
      if (this.filters.formulas.value !== formula) {
        this.pagination.currentPage = 1;
        this.filters.formulas.value = formula;
        this.getOrders();
      }
    },
    handleChangePage(page) {
      this.pagination.currentPage = page;
      this.getOrders();
    },
    // Basculer le status.
    toggleStatus(status) {
      if (status === 'ALL') {
        this.filters.status = null;
      } else if (status !== this.filters.status) {
        this.filters.status = status;
      } else {
        this.filters.status = null;
      }
      this.pagination.currentPage = 1;
      this.getOrders();
    },
    // Mise à jour de l'état des lignes sélectionnées dans la grille.
    changeStatus() {
      const selectedRows = this.gridApi.getSelectedRows();
      selectedRows.forEach((row) => {
        this.$http.put(`api/commandes/${row.id}`, {
          statut: this.newStatus,
        }, {
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/ld+json',
          },
        });
      });

      this.$vs.notify({
        title: 'Succès',
        text: 'Les commandes ont été mises à jour avec succès',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
        time: 5000,
      });

      this.popupStatut = false;
      this.getOrders();
    },
    afftecter() {
      const selectedRows = this.gridApi.getSelectedRows();
      const employee = this.prestataireNew.name === 'PP'
        ? `/api/employees/${this.teleconseillerNew}`
        : null;

      selectedRows.forEach(({ id }) => {
        this.$http.put(`api/commandes/${id}`, {
          prestataire: this.prestataireNew.name,
          employee,
        }, {
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/ld+json',
          },
        });
      });

      this.$vs.notify({
        title: 'Succès',
        text: 'Les commandes ont été mises à jour avec succès',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
        time: 5000,
      });

      this.popupActive = false;
      this.getOrders();
    },
    valider() {
      const selectedRows = this.gridApi.getSelectedRows();

      selectedRows.forEach(({ id, promotion, formule }) => {
        this.$http.put(`api/commandes/${id}`, { statut: 'EC' });

        // Dans le cas des offres débutant, on doit enregistrer la date de commande
        if (formule === 'Commande Debutant') {
          this.$http.put(`/api/offre_debutants/${promotion}`, {
            dateCommande: format(new Date(), 'MM/dd/yyyy  h:mm:ss a'),
          });
        }
      });

      this.$vs.notify({
        title: 'Succès',
        text: 'Les commandes ont été validées avec succès',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
        time: 5000,
      });

      this.popupActive = false;
      this.getOrders();
    },

    /**
     * Récupère les données depuis l'api et fait télécharger les données.
     */
    async getCsv() {
      const { id } = this.$store.state.AppActiveUser.user;
      const { isSuperAdmin } = this.$store.state.AppActiveUser;

      // Construction de l'url
      const url = this.$urlBuilder('/api/commandes/export')
        .appendQuery('code_postal', this.filters.postalCode)
        .appendQuery('employee', isSuperAdmin ? undefined : id)
        .appendQuery('formule', this.filters.formulas.value)
        .appendQuery('mandatary.lastName', this.filters.agent)
        .appendQuery('order[dateCreation]', 'desc')
        .appendQuery('reference', this.filters.reference)
        .appendQuery('statut', (!this.filters.status || this.filters.status === 'all') ? null : this.filters.status);

      if (isSuperAdmin) {
        url
          .appendQuery('employee.lastName', this.filters.teleconsultant)
          .appendQuery('prestataire', this.filters.serviceProvider);
      }

      /**
       * Définition des colonnes à récupérer.
       * [rename] est associer à la route /export, permet de renommer le libelle de la colonne.
       */
      columns
        .filter(({ superAdmin, field }) => {
          // Retire toutes les colonnes superAdmin si l'utilisateur est pas superAdmin.
          if ((superAdmin && !isSuperAdmin)) {
            return false;
          }

          // Suppression des colonne non souhaitées.
          if (['criteres', 'codesInsee'].includes(field)) {
            return false;
          }

          // Récupération des autres colonnes.
          return true;
        })
        .forEach(({ field, label }) => {
          const propertyArray = field.split('.');

          // Ajout de la queryString de séléction de propriétés à récupérer
          if (propertyArray.length > 1) {
            // On va selectionner une propriété imbriquée.
            url.appendQuery(`properties[${propertyArray[0]}][${propertyArray[1]}][rename]`, label);
          } else {
            // On va selectionner une propriété racine.
            url.appendQuery(`properties[${field}][rename]`, label);
          }
        });

      // Ajout d'une séléction de propriété qui ne figure pas dans la liste des colonnes.
      url
        .appendQuery('properties[codesPostaux][rename]', 'Codes Postaux')
        .appendQuery('properties[communes][rename]', 'Communes')
        .appendQuery('properties[criteresString][rename]', 'Critères');

      // Récupération des données.
      const date = format(new Date(2017, 10, 6), 'd-LL-y');
      try {
        // Loading
        this.$vs.loading({
          color: '#fff',
          container: '#get-csv-button',
          scale: 0.5,
        });

        // Fetch
        const { data: csv } = await this.$http.get(url.format(), {
          headers: {
            Accept: 'text/csv',
          },
        });

        // Téléchargement du CSV
        this.$download(`${date}-commandes.csv`, csv);
      } catch (error) {
        this.$vs.notify({
          title: 'Erreur',
          text: this.$parseError(error).message,
          color: 'danger',
        });
      } finally {
        // Arrêt loading
        this.$vs.loading.close('#get-csv-button > .con-vs-loading');
      }
    },

    /** Get all externalAgents */
    async getPrestataires() {
      try {
        const { data } = await this.$http.get('/api/prestataires');
        this.optionsPrestataire = data;
      } catch (error) {
        this.$vs.notify({
          title: 'erreur',
          text: this.$parseError(error).message,
          color: 'danger',
        });
      }
    },

    /** Récupère le nombre de commandes avec un statut spécifique pour les statistique. */
    async getOrdersStats() {
      this.$vs.loading({ container: '#stat-card', scale: 0.6 });

      const url = this.$urlBuilder('/api/commandes/statut');

      // Try to get data from remote
      try {
        const { data } = await this.$http.get(url.format());
        data.forEach(({ statut, count }) => {
          if (statut && this.statusFilters[statut] && statut !== 'RF') {
            this.$set(this.statusFilters[statut], 'value', count);
          }
        });
      } catch (error) {
        this.$vs.notify({
          title: 'erreur',
          text: this.$parseError(error).message,
          color: 'danger',
        });
      }
      this.$vs.loading.close('#stat-card > .con-vs-loading');
    },

    /** Récupère les commandes selon les filtres désirés. */
    async getOrders() {
      this.$vs.loading({ container: '#table', scale: 0.6 });
      const { id } = this.$store.state.AppActiveUser.user;
      const { isSuperAdmin } = this.$store.state.AppActiveUser;
      const data = [];

      // Set Search params
      const url = this.$urlBuilder('/api/commandes')
        .appendQuery('code_postal', this.filters.postalCode)
        .appendQuery('employee', isSuperAdmin ? undefined : id)
        .appendQuery('formule', this.filters.formulas.value)
        .appendQuery('itemsPerPage', this.pagination.perPage)
        .appendQuery('mandatary.lastName', this.filters.agent)
        .appendQuery('order[dateCreation]', 'desc')
        .appendQuery('page', this.pagination.currentPage)
        .appendQuery('reference', this.filters.reference)
        .appendQuery('pagination', true)
        .appendQuery('statut', (!this.filters.status || this.filters.status === 'all') ? null : this.filters.status);

      if (isSuperAdmin) {
        url
          .appendQuery('employee.lastName', this.filters.teleconsultant)
          .appendQuery('prestataire', this.filters.serviceProvider);
      }

      // Try to get data from remote
      try {
        const { data: orders } = await this.$http.get(url.format(), {
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/ld+json',
          },
        });
        const { 'hydra:member': result, 'hydra:view': pagination, 'hydra:totalItems': totalItems } = orders;
        const { 'hydra:last': lastPage } = pagination;
        this.pagination.total = Number((new URLSearchParams(lastPage)).get('page'));
        this.pagination.totalItems = totalItems;
        data.push(...result);
      } catch (error) {
        this.$vs.notify({
          title: 'erreur',
          text: this.$parseError(error).message,
          color: 'danger',
        });
      }

      // Set data to state
      const orders = data.map((value) => {
        const order = value;

        if (order && order.invoice && order.invoice.transaction && order.invoice.transaction.length && order.statut === 'EP') {
          const { status } = order.invoice.transaction[0];
          const comment = (order.commentaire && status) ? `${order.commentaire}, ` : order.commentaire;
          const payementError = status && `Erreur de paiement: ${status}`;

          order.commentaire = comment + payementError;
        }

        return order;
      });

      this.orders = orders;

      this.$vs.loading.close('#table > .con-vs-loading');
    },

    /** Get All Employee */
    async getEmployee() {
      const searchParams = new URLSearchParams();
      searchParams.append('serviceId', '11');
      searchParams.append('enabled', true);

      try {
        const { data } = await this.$http.get(`/api/employees?${searchParams.toString()}`);
        this.optionsTeleconseiller = data.map(({ firstName, lastName, id: value }) => ({ label: `${firstName} ${lastName}`, value }));
      } catch (err) {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      }
    },
  },
  async mounted() {
    this.getOrdersStats();
    this.getOrders();
    this.getPrestataires();
    this.getEmployee();
  },
};
</script>

<style lang="scss">
$status: ea,ec,nr,sb,clo,cb,ep,rf;

.table-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;

  &-status[data-status] span {
    background-color: attr(data-status color);
    border-radius: 4px;
    font-weight: bold;
    padding: 3px;
    color: white;
    text-align: center;
  }

  @each $item in $status {
    &[data-status=#{$item}] span {
      background-color: var(--color-#{$item});
    }
  }
}

.colors .vx-card__title {
  width: 100%;
  text-align: center;
}

.colors .vx-card__title h4 {
  width: 100%;
  padding-bottom: 10px;
  font-size: 38px;
}

.container {
  padding: 100px 0;
}

.colors .vx-card__title h6 {
  color: #fff !important;
}

.colors {
  margin-bottom: 50px
}
</style>

export default [
  {
    label: 'Statut',
    field: 'statut',
  },
  {
    label: 'Réference',
    field: 'reference',
  },
  {
    label: 'Formule',
    field: 'formule',
  },
  {
    label: 'Offre',
    field: 'offreCommande.libelle',
  },
  {
    label: 'Trigramme',
    field: 'trigramme',
  },
  {
    label: 'Nom négociateur',
    field: 'mandatary.lastName',
  },
  {
    label: 'Prénom négociateur',
    field: 'mandatary.firstName',
  },
  {
    label: 'email négociateur',
    field: 'mandatary.email',
  },
  {
    label: 'email négociateur',
    field: 'mandatary.email',
  },
  {
    label: 'Téléphone négociateur',
    field: 'mandatary.telephone',
  },
  {
    label: 'Prestataire',
    field: 'prestataire',
    superAdmin: true,
  },
  {
    label: 'Prénom téleconseiller',
    field: 'employee.firstName',
  },
  {
    label: 'Nb RDV initial',
    field: 'nbRdv',
  },
  {
    label: 'Nb RDV Recrédités+Commandés',
    field: 'nbRdsRecreditesCommandes',
  },
  {
    label: 'RDV pris',
    field: 'nbRdvPris',
  },
  {
    label: 'RDV réalisés',
    field: 'nbRdsRealises',
  },
  {
    label: 'RDV annulés',
    field: 'annulation',
  },
  {
    label: 'RDV restants',
    field: 'nbRdvRestants',
  },
  {
    label: 'RDV recrédités',
    field: 'nbRdsRecredites',
  },
  {
    label: 'Réseau',
    field: 'reseau',
  },
  {
    label: 'Date de création',
    field: 'dateCreation',
  },
  {
    label: 'Date de prise en charge',
    field: 'datePriseCharge',
  },
  {
    label: 'Date de clôture',
    field: 'dateCloture',
  },
  {
    label: 'Date du dernier RDV pris',
    field: 'dernierRdv.dateDebut',
  },
  {
    label: 'statut dernier RDV',
    field: 'dernierRdv.statut',
  },
  {
    label: 'Commentaire',
    field: 'commentaire',
  },
  {
    label: 'Code Insee',
    field: 'codesInsee',
  },
  {
    label: 'Critère',
    field: 'criteres',
  },
];
